import React from "react"
import Layout from "../components/layout"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import SEO from "../components/seo"

import "../styles/career.scss"
import CareerBanner from "../images/career_bg.png"

const Career = props => {
  return (
    <Layout>
      <SEO
        title="Career at S7 Code & Design"
        keywords={[`career`, `available positions`, `internship`]}
        description="S7 Code & Design is a place that allows you to show your skills and creativity
through challenging projects. Join us if you want to develop and work in high-performing team
on providing the highest quality to our costumers."
      />
      <div>
        <div
          className="career-banner"
          style={{ backgroundImage: `url(${CareerBanner})` }}
        >
          <div className="container h-100">
            <div className="row justify-content-center h-100">
              <div className="col-lg-9 col-12 career-banner--inner">
                <ul>
                  <li>Design</li>
                  <li>
                    <span> </span>Technologies
                  </li>
                  <li>
                    <span> </span>Future
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="career-main--content">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-10 col-12">
                <h1 className="text-center mb-5">Career</h1>
                <p>
                  Join us if you want to develop and work in a high-performing
                  team who will encourage you to accomplish goals you thought
                  were impossible. S7 code is a place that allows you to show
                  your skills and creativity through challenging projects. Grow
                  your career in a successful company that takes care of their
                  employees and value greatness over growth.
                </p>
              </div>
            </div>
            <div className="w-100"></div>
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-10 col-12">
                <h2 className="text-center mb-5 mt-5">Available positions</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center career-main--content-blocks">
            <div className="col-sm-10 col-12">
              <div className="row">
                <div className="col-lg-4">
                  <div className="career-main--content-block">
                    <Img
                      fluid={props.data.career_django_python_dev.childImageSharp.fluid}
                    />

                    <Link to="/career/django-python-developer">
                      <div className="block-link">
                        <p>Django/Python developer </p>
                        <p className="bounce">
                          <span></span>
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="career-main--content-block">
                    <Img fluid={props.data.career_csharp_dev.childImageSharp.fluid} />

                    <Link to="/career/c-sharp-developer">
                      <div className="block-link">
                        <p>C# developer</p>
                        <p className="bounce">
                          <span></span>
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div
                    className="career-main--content-block"
                    style={{ position: "relative", top: "8px" }}
                  >
                    <Img
                      fluid={props.data.career_android_dev.childImageSharp.fluid}
                    />

                    <Link to="/career/android-developer">
                      <div className="block-link">
                        <p>Android developer</p>
                        <p className="bounce">
                          <span></span>
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div
                    className="career-main--content-block"
                    style={{ position: "relative", top: "8px" }}
                  >
                    <Img
                      fluid={props.data.career_laravel_dev.childImageSharp.fluid}
                    />

                    <Link to="/career/laravel-symphony-developer">
                      <div className="block-link">
                        <p>Laravel/Symphony developer</p>
                        <p className="bounce">
                          <span></span>
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Career

export const pageQuery = graphql`
  query {
    career_front: file(relativePath: { eq: "career_front.png" }) {
      ...fluidImage
    }
    career_back: file(relativePath: { eq: "career_back.png" }) {
      ...fluidImage
    }
    career_inter: file(relativePath: { eq: "career_inter.png" }) {
      ...fluidImage
    }
    career_android_dev: file(relativePath: { eq: "career_android_dev.jpg" }) {
      ...fluidImage
    }
    career_csharp_dev: file(relativePath: { eq: "career_csharp_dev.jpg" }) {
      ...fluidImage
    }
    career_django_python_dev: file(relativePath: { eq: "career_django_python_dev.jpg" }) {
      ...fluidImage
    }
    career_laravel_dev: file(relativePath: { eq: "career_laravel_dev.jpg" }) {
      ...fluidImage
    }
  }
`
